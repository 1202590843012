<template>
  <v-container>
    <taxLevyList/>
  </v-container>
</template>

<script>

import taxLevyList from '../../components/shared/taxLevy/taxLevyList.vue';

export default {
  name: 'taxFund',
  components: {
    taxLevyList,
  },
};

</script>
