<template>
  <div>
    <v-container >
      <CRUDCriteria
        :showSearch="false"
        :showReset="true"
      >
        <template v-slot:criteriaRows>
          <v-row>
            <v-col cols="2">
              <v-select
                class="tax-levy-by-tax-year-select"
                v-model.number="criteriaState.taxYear"
                :items="taxYearRange"
                label="By Tax Year"
                @input="searchByCriteria"
              ></v-select>
            </v-col><v-col cols="3">
            <v-select
              class="tax-levy-by-district-select"
              v-model="criteriaState.taxDistrict"
              label="By District"
              item-text="districtDesc"
              item-value="_id"
              :items="districtList"
              @change="searchByCriteria"
            >
            </v-select>
          </v-col>
          </v-row>
        </template>
      </CRUDCriteria>
      <CRUDList
        :canAdd="true"
        :canEdit="true"
        :canSave="true"
        :headers="headers"
        :showTotal="true"
        :baseCrudKey="baseCrudKey"
        :createNewItem="newItemDialog"
        :customItemTotal="showMillsPercentTotal"
        toolbarTitle="Tax Unit"
        @loadCRUDList="handleLoadCRUDList"
        @upsertItem="upsrtTransaction"
        :defaultSortBy="sortBy"
      >
        <template v-slot:[`editedItem`]="{}">
          <taxLevyEditedItem
            :baseCrudKey="baseCrudKey"
          />
        </template>
      </CRUDList>
    </v-container>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
  mapState,
  mapMutations,
} from 'vuex';
import CRUDList from '../../common/base/crud/CRUDList.vue';
import CRUDCriteria from '../../common/base/crud/CRUDCriteria.vue';
import taxLevyEditedItem from './taxLevyEditedItem.vue';
import {
  amountToNumber,
  extractId,
} from '../../../util/shared/vue-global';
import {
  getFYList,
  toFiscalYear,
  toTaxYear,
} from '../../../util/shared/tmc-global';

const getHeaders = () => {
  const headers = [
    { text: 'To Fund', value: 'taxFund.fundNumber' },
    { text: 'Description', value: 'taxFund.description' || '' },
    { text: 'To Unit', value: 'taxUnit.toUnitDescForAppro' },
    { text: 'To Shared Fund', value: 'fund.fund' },
    { text: 'To District', value: 'taxDistrict.districtCode' },
    { text: 'Mills', value: 'mills' },
    { text: 'Percent', value: 'levyPercent' },
  ];
  return headers;
};

export default {
  name: 'taxLevyList',
  components: {
    CRUDCriteria,
    CRUDList,
    taxLevyEditedItem,
  },
  data: () => ({
    baseCrudKey: 'sharedTaxLevy',
    taxDistrictCrudKey: 'sharedTaxDistrict',
    headers: [],
    sortBy: [
      {
        column: 'taxFund.fundNumber',
        direction: 'desc',
      },
    ],
  }),
  created() {
    this.headers = getHeaders();
    this.initCriteria();
    this.loadLists();
    this.criteriaState.taxYear = toTaxYear(new Date(this.todaysDate));
    this.loadTaxRegYears();
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'index',
      'item',
      'items',
    ]),
    ...mapGetters(['todaysDate']),
    ...mapState({
      taxYearRange: (state) => {
        const registerdYears = state.shared.taxLevy.regYears || {};
        const tyList = getFYList(registerdYears);
        return tyList.sort((a, b) => b - a);
      },
    }),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    currentFiscalYear() {
      return toFiscalYear(new Date(this.todaysDate));
    },
    currentTaxYear() {
      return toTaxYear(new Date(this.todaysDate));
    },
    districtList() {
      const districtDescObj = this.items(this.taxDistrictCrudKey) || [];
      const updatedDistDescObj = districtDescObj.map((d) => ({
        ...d,
        districtDesc: `${d.districtCode || ''}  ${d.districtName || ''}`,
      }));
      updatedDistDescObj.sort((a, b) => (a.districtCode || '').localeCompare(b.districtCode));
      return [{ _id: null, districtCode: '', districtDesc: '' }, ...updatedDistDescObj];
    },
    showMillsPercentTotal() {
      const totalMills = (this.items(this.baseCrudKey) || []).reduce((acc, obj) => acc
        + amountToNumber(obj.mills), 0);
      const totalPercent = (this.items(this.baseCrudKey) || []).reduce((acc, obj) => acc
        + amountToNumber(obj.levyPercent), 0);
      return `Total Mills : ${totalMills} Total %: ${totalPercent}`;
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
  },
  methods: {
    ...mapActions('shared/taxLevy', [
      'loadTaxLevy',
      // 'upsertTaxLevy',
      'upsertBulkTaxLevy',
      'loadTaxRegYears',
    ]),
    ...mapActions('shared/taxDistrict', [
      'loadTaxDistrict',
    ]),
    ...mapActions('shared/taxFund', [
      'loadTaxFund',
    ]),
    ...mapActions('shared/taxUnit', [
      'loadTaxUnit',
    ]),
    ...mapActions('shared/fund', [
      'loadFunds',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      const criteria = this.criteriaState;
      if (this.criteriaState.taxDistrict) {
        this.loadTaxLevy({
          criteria,
          baseCrudKey,
        });
      }
    },
    async upsrtTransaction(item) {
      const { baseCrudKey } = this;
      const updatedItem = { ...item };
      updatedItem.mills = amountToNumber(updatedItem.mills);
      const result = this.bulkUpsertLevyPercent(updatedItem);
      for (let i = 0; i < (result || []).length; i += 1) {
        if (result[i].taxUnit) {
          result[i].taxUnit = extractId(result[i].taxUnit);
        }
        if (result[i].taxDistrict) {
          result[i].taxDistrict = extractId(result[i].taxDistrict);
        }
        if (result[i].taxFund) {
          result[i].taxFund = extractId(result[i].taxFund);
        }
        if (result[i].fund) {
          result[i].fund = extractId(result[i].fund);
        }
      }
      await this.upsertBulkTaxLevy({ item: result, baseCrudKey });
      this.handleLoadCRUDList();
    },
    loadLists() {
      this.loadTaxDistrict({
        criteria: {},
        baseCrudKey: 'sharedTaxDistrict',
      });
      this.loadTaxFund({
        criteria: {},
        baseCrudKey: 'sharedTaxFund',
      });
      this.loadFunds({
        criteria: {},
        baseCrudKey: 'sharedFund',
      });
      this.loadTaxUnit({
        // TO-DO: FETCH LATEST AVAILABLE FY TAX UNIT
        criteria: {
          fiscalYear: this.currentFiscalYear,
        },
        baseCrudKey: 'sharedTaxUnit',
      });
    },
    newItemDialog() {
      let newItem = {};
      newItem = {
        taxYear: this.criteriaState.taxYear,
      };
      if (this.criteriaState.taxDistrict && this.criteriaState.taxDistrict.length > 0) {
        newItem.taxDistrict = this.criteriaState.taxDistrict;
      }
      return newItem;
    },
    initCriteria() {
      // reset criteria while merge flag is true
      this.criteriaState = {};
    },
    searchByCriteria() {
      this.handleLoadCRUDList();
    },
    bulkUpsertLevyPercent(updatedItem) {
      const listFromState = (this.items(this.baseCrudKey));
      let updatedStateList = [];
      if (this.isNewItem) {
        updatedStateList = [...listFromState];
      } else {
        updatedStateList = listFromState.filter((obj) => obj._id !== updatedItem._id);
      }
      const updatedList = [...updatedStateList, updatedItem];
      const updatedLevyList = this.calculateLevyPercent(updatedList);
      return updatedLevyList;
    },
    calculateLevyPercent(levyList) {
      const levyListArray = [...levyList];
      const totalMills = (levyListArray || []).reduce((acc, obj) => acc
            + amountToNumber(obj.mills), 0);
      for (let i = 0; i < (levyListArray || []).length; i += 1) {
        levyListArray[i].levyPercent = levyListArray[i].mills / totalMills;
      }
      return levyListArray;
    },
  },
};

</script>
