<template>
   <v-container>
    <v-row>
      <v-col cols="3">
        <v-select id="taxLevyEditDialogTaxYearsSelect"
          class="tax-levy-edit-dialog-tax-years-select"
          v-model="editedItem.taxYear"
          :items="taxYearRange"
          readonly
          label="Tax Years"
          @blur="somethingChanged"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-select id="taxLevyEditDialogToDistrictSelect"
          class="tax-levy-edit-dialog-to-district-select"
          v-model="editedItem.taxDistrict"
          label="To District"
          :items="districtList"
          item-text="districtDesc"
          item-value="_id"
          @blur="somethingChanged"
          readonly
        >
        </v-select>
      </v-col>
      <v-col cols="4">
        <v-select id="taxLevyEditDialogToFundSelect"
          class="tax-levy-edit-dialog-to-fund-select"
          v-model="editedItem.taxFund"
          label="To Fund"
          :items="taxFundList"
          item-text="fundDesc"
          item-value="_id"
          @blur="somethingChanged"
          @change="prefillMills"
          autofocus
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-select id="taxLevyEditDialogToUnitSelect"
          class="tax-levy-edit-dialog-to-unit-select"
          v-model="editedItem.taxUnit"
          label="To Unit"
          :items="toUnitDescList"
          item-text="toUnitDesc"
          item-value="_id"
          @blur="somethingChanged"
        >
        </v-select>
      </v-col>
      <v-col cols="4">
        <v-select id="taxLevyEditDialogSharedFundSelect"
          class="tax-levy-edit-dialog-shared-fund-select"
          v-model="editedItem.fund"
          label="Shared Fund"
          :items="sharedFundList"
          item-text="sharedFundDesc"
          item-value="_id"
        >
        </v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field id="taxLevyEditDialogMillsText"
          class="tax-levy-edit-dialog-mills-text"
          v-model="editedItem.mills"
          label="Mills"
          @blur="somethingChanged"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-checkbox id="taxLevyEditDialog4MillsCheckbox"
          class="tax-levy-edit-dialog-4-mills-checkbox"
          v-model="editedItem.is4Mill"
          label="4 Mill"
        >
        </v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import {
  getFYList,
} from '../../../util/shared/tmc-global';
import {
  extractId,
} from '../../../util/shared/vue-global';

export default {
  name: 'taxLevyEditedItem',
  components: {
  },
  data: () => ({
    taxUnitCrudKey: 'sharedTaxUnit',
    taxDistrictCrudKey: 'sharedTaxDistrict',
    taxFundCrudKey: 'sharedTaxFund',
    sharedFundCrudKey: 'sharedFund',
  }),
  props: {
    baseCrudKey: {
      type: String,
      default: 'taxLevy-dialog',
    },
  },
  created() {
  },
  computed: {
    ...mapGetters('base/crud', [
      'item',
      'items',
      'index',
      'isVisible',
    ]),
    ...mapState({
      taxYearRange: (state) => {
        const registerdYears = state.shared.taxLevy.regYears || {};
        return getFYList(registerdYears);
      },
    }),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    districtList() {
      const districtDescObj = this.items(this.taxDistrictCrudKey) || [];
      const updatedDistDescObj = districtDescObj.map((d) => ({
        ...d,
        districtDesc: `${d.districtCode || ''}  ${d.districtName || ''}`,
      }));
      return [{ _id: null, districtCode: '', districtDesc: '' }, ...updatedDistDescObj];
    },
    toUnitDescList() {
      const unitDescObj = this.items(this.taxUnitCrudKey) || [];
      const updatedUnitDescObj = unitDescObj.map((u) => ({
        ...u,
        toUnitDesc: `${u.toUnitDescForAppro || ''}  ${u.description || ''}`,
      }));
      return [{ _id: null, toUnitDescForAppro: '', toUnitDesc: '' }, ...updatedUnitDescObj];
    },
    taxFundList() {
      const fundDescObj = this.items(this.taxFundCrudKey) || [];
      const updatedFundDescObj = fundDescObj.map((f) => ({
        ...f,
        fundDesc: `${f.fundNumber || ''}  ${f.description || ''}`,
      }));
      return [{ _id: null, fundNumber: '', fundDesc: '' }, ...updatedFundDescObj];
    },
    sharedFundList() {
      const sharedFundDescObj = this.items(this.sharedFundCrudKey) || [];
      const updatedSharedFundDescObj = sharedFundDescObj.map((f) => ({
        ...f,
        sharedFundDesc: `${f.fund || ''}  ${f.description || ''}`,
      }));
      return [{ _id: null, fund: '', sharedFundDesc: '' }, ...updatedSharedFundDescObj];
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setItem',
    ]),
    somethingChanged() {
      this.$emit('input', this.editedItem);
    },
    prefillMills() {
      if (this.isNewItem) {
        const selectedFund = this.taxFundList.find(
          (f) => (this.editedItem.taxFund) === extractId(f),
        );
        if (selectedFund.defaultMills !== null) {
          this.editedItem.mills = selectedFund.defaultMills;
        }
      }
      return this.editedItem.mills;
    },
  },
};
</script>
